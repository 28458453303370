export const BG_COVER = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/COVER%20POTRAIT.jpg?updatedAt=1709094297269`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/VIEW%20LANDSCAPE.jpg?updatedAt=1709094297201`;

export const BG_COVER_SLIDESHOW_1 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/COVER%20DALAM%20POTRAIT%201.jpg?updatedAt=1709094297185`;
export const BG_COVER_SLIDESHOW_2 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/COVER%20DALAM%20POTRAIT%202.jpg?updatedAt=1709094297011`;
export const BG_COVER_SLIDESHOW_3 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/COVER%20DALAM%20POTRAIT%203.jpg?updatedAt=1709094297026`;
export const BG_COVER_SLIDESHOW_4 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/COVER%20DALAM%20POTRAIT%204.jpg?updatedAt=1709094296873`;
export const BG_COVER_SLIDESHOW_5 = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/Slideshow/Slideshow-Cover-5.jpg?updatedAt=1698222444372`;

export const IMG_BRIDE_GROOM = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/FOTO%20BRIDE%20AND%20GROOM.jpg?updatedAt=1709094297074`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/LOVE%20STORY_2.jpg?updatedAt=1709372157993`;

export const BG_RSVP = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/BACKGROUND%20RSVP.jpg?updatedAt=1709372157796`;
export const BG_GIFTCARD = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/WED%20GIFT.jpg?updatedAt=1709094301315`;
export const BG_IG_FILTER = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/WED%20FILTER.jpg?updatedAt=1709372157927`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/LIVE%20STREAMING.jpg?updatedAt=1709372157956`;

export const IMG_FOOTER_1 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/FOOTER%201.jpg?updatedAt=1709094297070`;
export const IMG_FOOTER_2 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/FOOTER%202.jpg?updatedAt=1709094300823`;
export const IMG_FOOTER_3 = `https://ik.imagekit.io/farahluthfioktarina/elynadofan/FOOTER%203.jpg?updatedAt=1709094300414`;

export const IMG_LOGO = `https://user-images.githubusercontent.com/10141928/162121855-c5b68987-ca66-415e-b53a-42ac77189f29.png`;

export const IMG_MAN = `https://user-images.githubusercontent.com/10141928/160724867-90f36427-a2f3-4a21-908d-ee3f77422aff.jpg`;
export const IMG_GIRL = `https://user-images.githubusercontent.com/10141928/160724874-4a83d701-174a-4c69-8839-69e4521db5fa.jpg`;
export const IMG_QR_COVER = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/7.%20Wedding%20Filter.jpg?updatedAt=1695372281892`;

export const IMG_PHOTO_1 =
  'https://ik.imagekit.io/masansgaming/Template_Hendry_Clarissa/1_i7-EQ33yp.jpg?updatedAt=1679724192432';
export const IMG_PHOTO_2 =
  'https://ik.imagekit.io/masansgaming/Template_Hendry_Clarissa/2_NUamww_9b.jpg?updatedAt=1679724181595';
export const IMG_PHOTO_CONTENT =
  'https://user-images.githubusercontent.com/10141928/162121692-3e7a781f-6b2c-4cc4-be9e-004df53eb69d.jpg';

export const IMG_AYAT =
  'https://user-images.githubusercontent.com/10141928/160853754-d5b769cd-701f-4fe1-ba72-e493bee8a122.jpg';

export const IMG_SLIDE_1 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_1_n8StaSLkS.jpg?updatedAt=1638541151789`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_2_11_dNMMPw.jpg?updatedAt=1638541152275`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;
